export default class ProdConfig {
  // static baseURL = "http://138.197.225.63:9999";
  static baseURL = "https://staging-api.pro365.com";

  static chatKitInstanceLocatorId = "65b40690-beda-4191-acbe-f48369240106";

  static smartyStreetsAuthId = "694ab8ae-0b53-1ddf-1398-4f7f681e8dab";

  static smartyStreetsAuthToken = "XW7eBhS0arbjXIxnxlWW";
}
