export default class ApiEndpoints {
  constructor(baseURL) {
    console.log('url20', baseURL);
    this.apiURL = `${baseURL}/api`;

    this.SMARTYSTREETS_URL = `https://us-street.api.smartystreets.com`;

    this.SMARTYSTREETS_ZIPCODE_URL = `https://us-zipcode.api.smartystreets.com`;

    this.CHATROOMS_URL = `${this.apiURL}/users/me/contacted`;

    this.MESSAGEROOMS_URL = `${this.apiURL}/users/me/messaged`;

    this.CHAT_TOKEN_URL = `${this.apiURL}/chatkit/token`;

    this.CHAT_SOCKET_URL = `${baseURL}`;

    this.LOGIN_URL = `${this.apiURL}/auth/signin`;

    this.SIGNUP_URL = `${this.apiURL}/auth/signup`;

    this.ME_URL = `${this.apiURL}/users/me`;

    this.CHANGE_PASSWORD_URL = `${this.apiURL}/users/me/password`;

    this.UPLOAD_URL = `${this.apiURL}/uploads`;

    this.POST_JOB_URL = `${this.apiURL}/listings`;

    this.USER_LISTINGS = `${this.apiURL}/users/me/listings`;

    this.UPLOAD_PIC = `${this.apiURL}/uploads`;

    this.PRE_SIGNED_URL = `${this.apiURL}/uploads/signed-url`;

    this.SAVED = `${this.apiURL}/users/me/listings/saved`;

    this.MATCHED_USERS = `${this.apiURL}/listings`;

    this.GENERIC_USERS_URL = `${this.apiURL}/listings`;

    this.LISTINGS = `${this.apiURL}/listings`;

    this.LISTINGS_FOR_APPLICANTS = `${this.apiURL}/listings/for_applicants`;

    this.APPLICATIONS = `${this.apiURL}/users/me/applications`;

    this.APPLY_LISTING = `${this.apiURL}/listings`;

    this.MATCHED_LISTING = `${this.apiURL}/users/me/listings/matched`;

    this.ACCEPT_APPLICATION = `${this.apiURL}/applications`;

    this.ALL_APPLICANTS = `${this.apiURL}/users/search/applicants`;

    this.START_RECORDING = `${this.apiURL}/screenings/start`;

    this.CONTINUE_RECORDING = `${this.apiURL}/screenings/continue`;

    this.GET_RECORDING_STATUS = `${this.apiURL}/screenings/status`;

    this.FORGOT_PASSWORD = `${this.apiURL}/users/password/forgot`;

    this.BLOCK_USER = `${this.apiURL}/users`;

    this.DASHBOARD_DATA = `${this.apiURL}/users/me/dashboard`;

    this.RECENT_LISTINGS = `${this.apiURL}/recent_searches`;

    this.PUSH_TOKENS = `${this.apiURL}/users/me/push_token`;

    this.PUSH_NOTIFY = `${this.apiURL}/users/notify`;

    this.VERSION_INFO_APPLICANT = `${this.apiURL}/versioninfo/applicant`;

    this.VERSION_INFO_EMPLOYER = `${this.apiURL}/versioninfo/employer`;

    this.LINKEDIN = `${this.apiURL}/auth/linkedin`;
  }
}
