export default {
  ProfileScreen: 'ProfileScreen',
  HomeScreen: 'HomeScreen',
  ReviewScreen: 'ReviewScreen',
  SkillRequiredScreen: 'SkillRequiredScreen',
  PostJobScreen: 'PostJobScreen',
  CompensationScreen: 'CompensationScreen',
  JobDescriptionScreen: 'JobDescriptionScreen',
  PostReviewScreen: 'PostReviewScreen',
  PostSuccessScreen: 'PostSuccessScreen',
  MessageScreen: 'MessageScreen',
  ChatScreen: 'ChatScreen',
  SearchMessageScreen: 'SearchMessageScreen',
  MessageComponent: 'MessageComponent',
  SuccessfulScreen: 'SuccessfulScreen',
  EditProfileScreen: 'EditProfileScreen',
  ChangeProfilePicScreen: 'ChangeProfilePicScreen',
  AccountSettingScreen: 'AccountSettingScreen',
  UploadResumeScreen: 'UploadResumeScreen',
  ChangePasswordScreen: 'ChangePasswordScreen',
  VideoInterview: 'VideoInterview',
  EditSkillScreen: 'EditSkillScreen',
  ResumeScreen: 'ResumeScreen',
  QuestionScreen: 'QuestionScreen',
  PostRecordingScreen: 'PostRecordingScreen',
  FinalScreen: 'FinalScreen',
  MapScreen: 'MapScreen',
  SearchScreen: 'SearchScreen',
  JobScreen: 'JobScreen',
  ViewProfile: 'ViewProfile',
  LoginScreen: 'LoginScreen',
  Main: 'Main',
  Login: 'Login',
  SettingsScreen: 'SettingsScreen',
  SignUp: 'SignUp',
  SearchJobScreen: 'SearchJobScreen',
  FiltersScreen: 'FiltersScreen',
  ViewJobs: 'ViewJobs',
  FinancialAnalyst: 'FinancialAnalyst',
  ViewJobsScreen: 'ViewJobsScreen',
  ApplicantRegistrationForm: 'ApplicantRegistrationForm',
  SearchAndSignUpScreen: 'SearchAndSignUpScreen',
  GenericRegFormScreen: 'GenericRegFormScreen',
  ChangePic: 'ChangePic',
  ListScreen: 'ListScreen',
  SettingUpScreen: 'SettingUpScreen',
  EmployerRegistrationSuccessScreen: 'EmployerRegistrationSuccessScreen',
  ChangePasswordEmployerScreen: 'ChangePasswordEmployerScreen',
  EmployerMapScreen: 'MapScreen',
  PostEditScreen: 'PostEditScreen',
  JobFiltersScreen: 'JobFiltersScreen',
  ViewResumeScreen: 'ViewResumeScreen',
  ForgetPasswordScreen: 'ForgetPasswordScreen',
  EditCompanyInfo: 'EditCompanyInfo',
  EmployerJobScreen: 'EmployerJobScreen',
  SplashScreen: 'SplashScreen',
  Start: 'Start',
  ApplicantStartUpMapScreen: 'ApplicantStartUpMapScreen',
  EmployerStartScreen: 'EmployerStartScreen',
  UploadLogoScreen: 'UploadLogoScreen',
  Jobs: 'Jobs',
};
