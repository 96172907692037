export function updateFileUrl(fileUrl) {
  return ({
    type: 'UPDATE_FILE_URL',
    payload: fileUrl,
  });
}
export function updateLoadingState(isLoading) {
  return ({
    type: 'UPDATE_LOADING_STATE',
    payload: isLoading,
  });
}

export function updateInterviewType(isInterviewTypeVideo) {
  return ({
    type: 'UPDATE_INTERVIEW_TYPE',
    payload: isInterviewTypeVideo,
  });
}

export function updateJobDetails(jobDetails) {
  return ({
    type: 'UPDATE_JOB_DETAILS',
    payload: jobDetails,
  });
}

export function setUserData(userData) {
  return ({
    type: 'SET_USER_DATA',
    payload: userData,
  });
}

export function clearUserData() {
  return ({
    type: 'CLEAR_USER_DATA',
  });
}

export function updateCurrentQuestionNumber(questionNumber) {
  return ({
    type: 'UPDATE_CURRENT_QUESTION_NUMBER',
    payload: questionNumber,
  });
}

export function updateCurrentPosition(currentPosition) {
  return ({
    type: 'SET_USER_DATA',
    payload: currentPosition,
  });
}

export function updateApplicantSearchFilterParams(applicantSearchFilterParams) {
  return ({
    type: 'APPLICANT_FILTER_PARAMS',
    payload: applicantSearchFilterParams,
  });
}

export function deleteApplicantSearchFilterParams() {
  return ({
    type: 'DELETE_APPLICANT_SEARCH_FILTER_PARAMS',
  });
}

export function updateSearchParams(SearchParams) {
  return ({
    type: 'UPDATE_SEARCH_PARAMS',
    payload: SearchParams,
  });
}

export function addJobsDept(dept, title) {
  return ({
    type: 'ADD_JOBS_DEPT',
    payload: { dept, title },
  });
}

export function addEmployerJobFilters(filterParams) {
  return ({
    type: 'ADD_EMPLOYER_JOB_FILTERS',
    payload: filterParams,
  });
}

export function recentJobsData(data) {
  return ({
    type: 'RECENT_JOBS',
    payload: data,
  });
}

export function userJobsData(data) {
  return ({
    type: 'USER_JOBS',
    payload: data,
  });
}

export function setGenericRegFormComponentStatus(isGenericRegFormComponentClosed) {
  return ({
    type: 'GRFC',
    payload: isGenericRegFormComponentClosed,
  });
}

export function userChatRooms(rooms) {
  return ({
    type: 'USER_CHAT_ROOMS',
    payload: rooms,
  });
}

export function chatRoomUpdate(isChatRoomUpdate) {
  return ({
    type: 'CHATROOM_UPDATE',
    payload: isChatRoomUpdate,
  });
}

export function navigateScreen(activeScreenName) {
  return ({
    type: 'CHANGE_SCREEN',
    payload: activeScreenName,
  });
}

export function updateUnreadMessages(unreadMessagesCount) {
  return ({
    type: 'UPDATE_UNREAD_MESSAGES',
    payload: unreadMessagesCount,
  });
}

export function UpdateJobScreenParams(jobParams) {
  return ({
    type: 'NEW_JOB_PARAMS',
    payload: jobParams,
  });
}

export function updateLoginStatus(status) {
  console.log('>>>>?? login Status Changed', status);
  return ({
    type: 'UPDATE_LOGIN_STATUS',
    payload: status,
  });
}
