const tintColor = '#2f95dc';

export default {
  transparent: 'rgba(0,0,0,0)',
  tintColor,
  tabIconDefault: '#ccc',
  tabIconSelected: '#006938',
  tabBar: '#fefefe',
  errorBackground: 'red',
  errorText: '#fff',
  warningBackground: '#EAEB5E',
  warningText: '#666804',
  noticeBackground: tintColor,
  noticeText: '#fff',
  gray: '#e5e5e5',
  lightGreyColor: '#EDECEB',
  white: '#ffffff',
  whiteSmoke: '#f7f7f7',
  black: '#000000',
  wedgeWood: '#566D81',
  yellow: '#ffd204',
  lightYellow: '#FFF4D6',
  lightRed: '#FEDBDB',
  lightGrey: '#eee',
  darkGrey: 'grey',
  toastRed: 'rgb(256,94,86)',
  toastRedBorder: 'rgb(236,74,66)',
  toastBlue: 'rgb(57,184,247)',
  toastBlueBorder: 'rgb(20,107,144)',
  toastGreen: '#39d080',
  toastOrange: '#E7B229',
  toastOrangeBorder: '#E0A020',
  toastDisabled: 'rgba(241, 42, 37, 0.4)',
  primary: '#2F4260',
  secondary: '#F0F1F3',
  opaqueBlue: '#061A35',
  about: '#D8D8D8',
  red: '#FF0000',
  active: '#FFFFFF',
  activeMenuBack: '#cddffa',
  inactive: '#FFAB91',
  green: '#1F8430',
  orange: '#EA7676',
  veryLightGreen: '#98E4A7',
  skyblue: '#35AFD5',
  successfulScreenBackground: '#E0F6FF',
  //------------
  themeColor: '#0268FF',
  //themeColor: '#0002FF', // new themeColor
  lightGreenBackground: '#E4F8F6',
  lightBlueBackground: '#E5EFFF',
  activeBlue: '#0268FF',
  //activeBlue: '#0002FF', // new themeColor
  activeGreen: '#00C7B3',
  activeRed: '#EE5F60',
  darkBlue: '#0A52BC',

  /* some Applicant colors  */
  headBorderBottomColor: '#979797',
  cancelFont: '#E02020',
  findJobsButtonColor: '#B7B7B7',
  searchTermsAdditionalText: '#B7B7B7',
  recentSearchesColor: '#2A2A2A',

  interimGreyFont: '#6A6A6A',
  darkGreyFont: '#2A2A2A',
  signupAndLoginToViewColor: '#B1B1B1',
  signupButton: '#1F8430',
  searchTermsListBorderColor: '#979797',
  termsBg: '#A4D6B1',

  slideInnderBorderColor: "#AEAEAE",
  appliedLabelBg: '#D0FFD8',
  savedLabelBg: '#FFEEC0',

  lightGreyFontContrast: "#B7B7B7",


  jobCardGradientFrom: '#0268FF',
  jobCardGradientTo: '#2A2A72',
  savedLabelFont: '#BB8900',

  jobCardCompanyTitle: '#404040',
  lightPurpleBackgroundColor: '#C0CEFF',
  medicalGreen: '#00C7B3',

  slategray: '#8190a7',
  mildGrey: '#cccccc',
  lightMildGrey: '#eeeeee',//for app background

  veryLightGrey: '#F8F8F8',
};
