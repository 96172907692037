import devConfig from './config.dev';
import prodConfig from './config.prod';
import appConfig from '../../../config.json';

const env = process.env.NODE_ENV;

// env will be either of development or production

const config = env === 'development' ? devConfig : prodConfig;

export default { ...config, ...appConfig, };
