import NavigationScreenNames from "../utils/constants/NavigationScreenNames";

const INITIAL_STATE = {
  fileUrl: null,
  isLoading: false,
  isInterviewTypeVideo: false,
  jobDetails: null,
  userData: null,
  currentPosition: 0, // current registration step
  questionNumber: 1,
  applicantSearchFilterParams: {
    minDistanceRange: 0,
    maxDistanceRange: 10,
    minSalaryRange: 50,
    maxSalaryRange: 100,
    paymentType: 'HOURLY',
    employmentTypes: [],
    dateArray: [],
    hasResume: true,
    hasVideo: true,
  },
  searchParams: {
    locationText: '',
    searchText: '',
  },
  jobsDept: null,
  jobsTitle: null,
  employerJobsFilterParams: null,
  recentJobs: [],
  userJobs: [],
  isGenericRegFormComponentClosed: false,
  chatRooms: [],
  isChatRoomUpdate: false,
  activeScreen: NavigationScreenNames.HomeScreen,
  unreadMessagesCount: 0,
  jobScreenParams: null,
  isLoggedIn: false,
};

const Reducer = function (state = INITIAL_STATE, action) {
  //console.log(action);
  switch (action.type) {
    case 'UPDATE_FILE_URL':
      return {
        ...state,
        fileUrl: action.payload,
      };
    case 'UPDATE_LOADING_STATE':
      return {
        ...state,
        isLoading: action.payload,
      };
    case 'UPDATE_INTERVIEW_TYPE':
      return {
        ...state,
        isInterviewTypeVideo: action.payload,
      };
    case 'UPDATE_JOB_DETAILS':
      return {
        ...state,
        jobDetails: action.payload,
      };
    case 'SET_USER_DATA':
      return {
        ...state,
        userData: { ...state.userData, ...(action.payload) },
      };
    case 'UPDATE_CURRENT_QUESTION_NUMBER':
      return {
        ...state,
        questionNumber: action.payload,
      };
    case 'UPDATE_STEP':
      return {
        ...state,
        currentPosition: action.payload,
      };
    case 'CLEAR_USER_DATA':
      return {
        ...state,
        userData: null,
      };
    case 'APPLICANT_FILTER_PARAMS':
      return {
        ...state,
        applicantSearchFilterParams: action.payload,
      };
    case 'DELETE_APPLICANT_SEARCH_FILTER_PARAMS':
      return {
        ...state,
        applicantSearchFilterParams: null,
      };
    case 'UPDATE_SEARCH_PARAMS':
      return {
        ...state,
        searchParams: action.payload,
      };
    case 'ADD_JOBS_DEPT':
      return {
        ...state,
        jobsDept: action.payload.dept,
        jobsTitle: action.payload.title,
      };
    case 'ADD_EMPLOYER_JOB_FILTERS':
      return {
        ...state,
        employerJobsFilterParams: action.payload,
      };
    case 'RECENT_JOBS':
      return {
        ...state,
        recentJobs: action.payload,
      };
    case 'USER_JOBS':
      return {
        ...state,
        userJobs: action.payload,
      };
    case 'GRFC':
      return {
        ...state,
        isGenericRegFormComponentClosed: action.payload,
      };
    case 'USER_CHAT_ROOMS':
      return {
        ...state,
        chatRooms: action.payload,
      };
    case 'CHATROOM_UPDATE':
      return {
        ...state,
        isChatRoomUpdate: action.payload,
      };
    case 'CHANGE_SCREEN':
      return {
        ...state,
        activeScreen: action.payload,
      };
    case 'UPDATE_UNREAD_MESSAGES':
      return {
        ...state,
        unreadMessagesCount: action.payload,
      };
    case 'NEW_JOB_PARAMS':
      return {
        ...state,
        jobScreenParams: action.payload,
      };
    case 'UPDATE_LOGIN_STATUS':
      return {
        ...state,
        isLoggedIn: action.payload,
      };
    default:
      return state;
  }
};
export default Reducer;
