import React from 'react';
import {
  Alert,
  AsyncStorage, Dimensions, Linking, Platform,
} from 'react-native';
import NetInfo from '@react-native-community/netinfo';
import axios from 'axios';
import { Toast } from 'native-base';
import SyncStorage from 'sync-storage';
import { Notifications } from 'expo';
import ExpoConstants from 'expo-constants';
import * as Permissions from 'expo-permissions';
import moment from 'moment';
import get from 'lodash/get';
import EStyleSheet from "react-native-extended-stylesheet";
import { toast } from 'react-toastify';
import Constants from "./constants/Constants";
import config from './config/config';
import 'intl';
import 'intl/locale-data/jsonp/en';
import * as VideoThumbnails from "expo-video-thumbnails";
import NavigationService from "./NavigationService";
import NavigationScreenNames from "./constants/NavigationScreenNames";
import ApiEndpoints from "./constants/ApiEndpoints";
import store from "../store/Store";
import { updateLoginStatus } from "../store/Actions";

let spinnerRef;
let chatkitUser;
let activeRoomId;
let unreadMessage = false;
const apiEndpointObjects = [];

let width = Platform.OS !== 'web' ? Dimensions.get('window').width : window.innerWidth;
let height = Platform.OS !== 'web' ? Dimensions.get('window').height : window.innerHeight;

if (Platform.OS === 'web') {
  window.addEventListener('resize', () => {
    width = window.innerWidth;
    height = window.innerHeight;
  });
}

export const MAX_HOURLY_RATE = 100;
export const MAX_SALARY_RATE = 200000;

const style = {
  toastStyle: {
    marginTop: '3rem',
    // justifyContent: 'center',
    width: "100%",
    alignSelf: 'center',
  },
  questionToastStyle: {
    marginTop: '3rem',
    // justifyContent: 'center',
    width: "100%",
    alignSelf: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.4)',
  },
};
const styles = EStyleSheet.create(style);


export class Gen {
  static errors = {
    400: "Bad request",
    401: "You are not authorized",
    500: "Internal server error",
    404: "Resource not found",
  }

  static initConsole() {
    const env = ExpoConstants.manifest.releaseChannel;

    if (env === 'default') {
      console.log = () => {
      };
    }
  }

  static _DEV_ = () => {
    const env = ExpoConstants.manifest.releaseChannel;
    return env === 'default';
  };

  static getError(response) {
    return get(response, 'data.error') || get(response, 'data.message_code');
  }

  static handleError(response) {
    Gen.showToastError(Gen.getError(response) || Gen.error[response.statusCode] || 'Something went wrong');
  }

  static initAxios() {
    axios.interceptors.request.use((config) => {
      const token = Gen.getFromSyncStorage(Constants.TOKEN);
      // const token = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiI1ZDI3MjVmOTU5ZGViMzc3MjBjYjA0MGYiLCJpYXQiOjE1NjI5MTQ0NjcsImV4cCI6MTY0OTMxNDQ2N30.NU8cU7fbvypgwxdK2QS_JwT-S6wkpv-8QZ3PEa2XTLc"
      console.log('token in the request interceptor', token);
      config.headers.Authorization = `Bearer ${token}`; // eslint-disable-line no-param-reassign
      Gen.checkInternet();
      return config;
    }, err => Promise.reject(err));

    axios.interceptors.request.use((request) => {
      console.log('Starting Request', request);
      return request;
    });

    axios.interceptors.response.use((response) => {
      console.log('Response:', response);
      if (this.isUserLoggedIn() && (this.isUserLoggedIn() !== store.getState().isLoggedIn)) {
        store.dispatch(updateLoginStatus(Constants.USER_LOGGED_IN));
      }
      return response;
    }, async (error) => {
      Gen.hideLoder();
      // console.log('error : ', error);
      // !error.response && Gen.showToastError('Something went wrong');
      console.log('error response : ', error.response);
      Gen.handleError(error.response);
      if (error.response.data.message_code === 'ACCOUNT_DELETED') {
        Gen.showToastError('THIS ACCOUNT IS DELETED');
        await Gen.removeFromLocalStorage(Constants.TOKEN);
        await Gen.removeFromLocalStorage(Constants.UNHIDEBOTTOMTAB);
        NavigationService.reset(NavigationScreenNames.Start, Math.random());
      }
      if (error.response.data.message_code === 'UNAUTHORIZED') {
        Gen.showToastError('Unauthozied');
        await Gen.removeFromLocalStorage(Constants.TOKEN);
        await Gen.removeFromLocalStorage(Constants.UNHIDEBOTTOMTAB);
        NavigationService.reset(NavigationScreenNames.Login, Math.random());
      }
      return error;
    });
    if (!this.isUserLoggedIn()) {
      store.dispatch(updateLoginStatus(Constants.USER_LOGGED_OUT));
    }
  }

  // https://stackoverflow.com/questions/40249604/react-native-how-to-determine-if-device-is-iphone-or-ipad/40252981
  static getDeviceType() {
    const aspectRatio = height / width;
    if (aspectRatio > 1.6) {
      return 'phone';
    }
    return 'tablet';
  }

  static async checkInternet() {
    if (Gen.isWeb()) {
      const status = window.navigator.onLine;
      !status && Gen.showToastError('Internet Connection is not available');
    } else {
      const { isConnected } = await NetInfo.fetch();
      !isConnected && Gen.showToastError('Internet Connection is not available');
    }
  }

  static async interntConnection() {
    const { isConnected } = await NetInfo.fetch();
    return Gen.isWeb() ? window.navigator.onLine : isConnected;
  }

  static isWebInternetConnected() {
    return window.navigator.onLine;
  }

  static updateDimension() {
    width = window.innerWidth;
    height = window.innerHeight;
    console.log('Window resized: updated dimensions >>>>>>>>> ', width, height);
  }

  static getDimension() {
    if (Platform.OS === 'web') {
      return { width, height };
    } if (Gen.getDeviceType() === 'tablet') {
      return { width: width / 2, height: (2 * height) / 3 };
    }
    return { width, height };
  }

  static getActiveRouteName(navigationState) {
    if (!navigationState) {
      return null;
    }
    const route = navigationState.routes[navigationState.index];
    // dive into nested navigators
    if (route.routes) {
      return Gen.getActiveRouteName(route);
    }
    return route;
  }

  static setActiveScreenData(screenData) {
    this._screenData = screenData;
  }

  static getActiveScreenData() {
    return this._screenData || {};
  }

  static showToast(text, type) {
    spinnerRef.hideSpinner();
    Toast.show({
      text,
      duration: 3000,
      type,
      position: 'top',
      textStyle: {
        textAlign: "center",
      },
      style: styles.toastStyle,
    });
  }

  static async saveApplicantProfile(data) {
    Gen.showLoader();
    const {
      skills, detailsObj, pic, resume,
    } = data;
    const
      {
        firstName, lastName, street, city, state, zipcode, phoneNumber, checkbox,
      } = detailsObj;

    try {
      const result = await axios.put(Gen.getApiEndpoints().ME_URL, {
        firstName,
        lastName,
        applicant: {
          skills,
          photo: pic,
          authorizedToWorkInUS: checkbox,
          resume,
          phoneNumber,
          address: {
            street,
            city,
            state,
            zipcode,
          },
        },
      }, { headers: { Authorization: `Bearer ${Gen.getFromSyncStorage(Constants.TOKEN)}` } });
      // console.log('applicant Data', result);
      if (result) {
        await Gen.startScreening();
      }
      return result;
    } catch (e) {
      Gen.hideLoder();
      Gen.showToastError(e.response.data.error);
      // console.log('request failed ', e);
      return null;
    }
  }

  static async startScreening() {
    try {
      const result = await axios.post(Gen.getApiEndpoints().START_RECORDING, {
        type: 'video',
      });
      if (result) {
        Gen.hideLoder();
      }
    } catch (e) {
      Gen.hideLoder();
      Gen.showToastError(e.response.data.error);
    }
  }


  static showToastWithButton(text, type) {
    spinnerRef.hideSpinner();
    Toast.show({
      text,
      duration: 3000,
      position: 'top',
      type,
      buttonText: 'Okay',
      textStyle: {
        textAlign: "center",
      },
      buttonTextStyle: {
        fontWeight: 'bold',
      },
      style: styles.toastStyle,
    });
  }

  static isWeb = () => Platform.OS === 'web';

  static showLoader() {
    spinnerRef && spinnerRef.showSpinner(); // NOTE: this check is only needed because in hot reloading react kills the refs.
  }

  static hideLoder() {
    spinnerRef && spinnerRef.hideSpinner();
  }

  static getReadableDate(date) {
    return moment(date).format('MM/DD/YY');
  }

  // static uploadFile(fileType) {
  //   try {
  //     const uploadResult = await axios.post(Gen.getApiEndpoints().UPLOAD_PIC, {
  //
  //     }, {
  //       headers: {
  //         "Content-Type": "multipart/form-data"
  //       }
  //     });
  //     if (uploadResult) {
  //       alert('Profile image updated successfully !');
  //       console.log('result', JSON.stringify(uploadResult));
  //     }
  //   } catch (e) {
  //     console.log('request to add skill failed ', e);
  //   }
  // }

  static showToastError(text) {
    Gen.isWeb() ? toast.error(text) : Gen.showToast(text, "danger");
  }

  static showToastWarning(text) {
    Gen.isWeb() ? toast.warn(text) : Gen.showToast(text, "warning");
  }

  static showToastSuccess(text) {
    Gen.isWeb() ? toast.success(text) : Gen.showToast(text, "success");
  }

  static showQuestionToast(text) {
    Toast.show({
      text,
      duration: 200000,
      position: 'top',
      textStyle: {
        textAlign: "center",
      },
      style: styles.questionToastStyle,
    });
  }

  // NOTE: type is an enum with possible values 'none', 'wifi', 'cellular', 'unknown'
  static setInternetConnectionType(type) {
    Gen.connectionType = type;
  }

  static getInternetConnectionType() {
    return Gen.connectionType;
  }

  // NOTE: only call when you are sure that setInternetConnectionType has been called once
  static isInternetAvailable() {
    if (!Gen.getInternetConnectionType()) {
      throw ("Internet connection hasn't been initialized yet");
    }

    return Gen.getInternetConnectionType() !== 'none';
  }

  static async setInLocalStorage(key, data) {
    const storedData = await AsyncStorage.setItem(key, JSON.stringify(data));
    return storedData;
  }

  static async getFromLocalStorage(key) {
    const data = await AsyncStorage.getItem(key);
    // alert(data)
    return data;
  }

  static async removeFromLocalStorage(key) {
    const data = await SyncStorage.remove(key);
    if (key === Constants.TOKEN) {
      store.dispatch(updateLoginStatus(Constants.USER_LOGGED_OUT));
    }
    return data;
  }

  static getFromSyncStorage(key) {
    return SyncStorage.get(key);
  }

  static isAllowedToSwipe() {
    return Gen.isUserLoggedIn(Constants.UNHIDEBOTTOMTAB);
  }

  static changeBaseURL(newURL) {
    Gen.setInSyncStorage(Constants.BASE_URL, newURL);
  }

  static getApiEndpoints() {
    const baseURL = Gen.getFromSyncStorage(Constants.BASE_URL) || Constants.STAGING_URL;
    if (!apiEndpointObjects[baseURL]) {
      apiEndpointObjects[baseURL] = new ApiEndpoints(baseURL);
    }
    return apiEndpointObjects[baseURL];
  }

  static async setInSyncStorage(key, value) {
    await SyncStorage.set(key, value);
  }

  static isModuleApplicant() {
    return Gen.getAppModule() === 'APPLICANT';
  }

  static getAppModule() {
    return config.module;
  }

  static async initApp() {
    await SyncStorage.init();

    if (Gen.isWeb()) {
      // add the google script to the body
      const googleMapsScript = document.createElement('script');

      // TODO: read the key from config
      googleMapsScript.setAttribute('src', 'https://maps.googleapis.com/maps/api/js?key=AIzaSyCu6UdrLi15Ld0ClTBnzrbN-2kCRLeSk-E');
      document.head.appendChild(googleMapsScript);
    }

    Gen.initAxios();
    Gen.initConsole();
  }

  static isUserLoggedIn() {
    return !!Gen.getFromSyncStorage(Constants.TOKEN);
  }

  static isUserGuest() {
    return !Gen.getFromSyncStorage(Constants.UNHIDEBOTTOMTAB);
  }

  static getHeader() {
    return {
      Authorization: `Bearer ${Gen.getFromSyncStorage(Constants.TOKEN)}`,
    };
  }

  static sendFile(presignedurl, file, onSuccess, onFail, onProgress) {
    // from http://blog.rudikovac.com/react-native-upload-any-file-to-s3-with-a-presigned-url/
    const xhr = new XMLHttpRequest();
    xhr.open('PUT', presignedurl);
    xhr.upload.addEventListener('progress', (e) => {
      // handle notifications about upload progress: e.loaded / e.total
      // console.log('progress');
      // console.log(e);
      onProgress(e);
    }, false);
    xhr.onreadystatechange = function () {
      if (xhr.readyState === 4) {
        if (xhr.status === 200) {
          // Successfully uploaded the file.
          // alert('successfully uploaded presignedurl');
          // console.log(xhr);
          onSuccess(xhr.responseURL.substring(0, xhr.responseURL.indexOf('?')));
        } else {
          // The file could not be uploaded.
          // alert('failed to upload presignedurl');
          // console.log(xhr);
          onFail(file.uri);
        }
      }
    };
    xhr.setRequestHeader('x-amz-acl', 'public-read');
    xhr.setRequestHeader('Content-Type', file.type);
    xhr.send(file);
  }

  static async getPermissionsForPushNotification() {
    const { status: existingStatus } = await Permissions.getAsync(
      Permissions.NOTIFICATIONS,
    );
    let finalStatus = existingStatus;

    // only ask if permissions have not already been determined, because
    // iOS won't necessarily prompt the user a second time.
    if (existingStatus !== 'granted') {
      // Android remote notification permissions are granted during the app
      // install, so this will only ask on iOS
      const { status } = await Permissions.askAsync(Permissions.NOTIFICATIONS);
      finalStatus = status;
    }

    // Stop here if the user did not grant permissions
    if (finalStatus !== 'granted') {
      return false;
    }
    return finalStatus;
  }

  static async registerForPushNotificationsAsync() {
    console.log('push notif');
    const { status: existingStatus } = await Permissions.getAsync(
      Permissions.NOTIFICATIONS,
    );
    let finalStatus = existingStatus;

    // only ask if permissions have not already been determined, because
    // iOS won't necessarily prompt the user a second time.
    if (existingStatus !== 'granted') {
      // Android remote notification permissions are granted during the app
      // install, so this will only ask on iOS
      const { status } = await Permissions.askAsync(Permissions.NOTIFICATIONS);
      finalStatus = status;
    }

    // Stop here if the user did not grant permissions
    if (finalStatus !== 'granted') {
      return;
    }

    // Get the token that uniquely identifies this device
    const token = await Notifications.getExpoPushTokenAsync();
    try {
      const res = await axios.put(Gen.getApiEndpoints().PUSH_TOKENS, {
        token: {
          value: token,
        },
      });
      console.log('push res', res);
    } catch (e) {
      console.log(e.response);
    }
    console.log('token', token);
    // POST the token to your backend server from where you can retrieve it to send push notifications.
  }

  static SpinnerRef(r) {
    spinnerRef = r;
  }

  static GetRef() {
    return spinnerRef;
  }

  static loaderStatus() {
    return spinnerRef.state.spin;
  }

  static async validateAddress({
    streetAddress, city, state, zip, phoneNumber,
  }) {
    const res = await axios.get(`${Gen.getApiEndpoints().SMARTYSTREETS_URL}/street-address`, {
      params: {
        // "auth-id": config.smartyStreetsAuthId,
        // "auth-token": config.smartyStreetsAuthToken,
        street: streetAddress,
        state,
        city,
        zipcode: zip,
        key: '16182431913915113',
      },
      headers: {
        Referer: 'http://localhost/', // TODO: change this to pro365.com after coordinating with Adam
      },
    });
    console.log('res', res);
    if (res.data.length < 1) {
      return {
        res: false,
        desc: "Invalid Address",
      };
    }

    const zipRes = await this.validateZip(streetAddress, city, state);
    const filteredZip = zipRes.filter(ele => ele.zipcode === zip);
    if (filteredZip.length < 1) {
      return {
        res: false,
        desc: "ZipCode is incorrect",
      };
    }
    const phoneRes = this.validatePhoneNumber(phoneNumber);
    if (!phoneRes) {
      return {
        res: false,
        desc: "Phone Number is not correct",
      };
    }
    return {
      res: true,
      desc: "All green",
    };
  }

  static async validateZip(streetAddress, city, state) {
    let res;
    try {
      res = await axios.get(`${Gen.getApiEndpoints().SMARTYSTREETS_ZIPCODE_URL}/lookup`, {
        params: {
          // "auth-id": config.smartyStreetsAuthId,
          // "auth-token": config.smartyStreetsAuthToken,
          street: streetAddress,
          state,
          city,
          key: '16182431913915113',
        },
        headers: {
          Referer: 'http://localhost/', // TODO: change this to pro365.com after coordinating with Adam
        },
      });
      console.log('zip', res);
      if (res.data[0].zipcodes) {
        return res.data[0].zipcodes;
      }

      Gen.showToastError(res.data[0].status);
    } catch (e) {

    }
  }

  static async validateAddressApplicant({
    street, city, state, zipcode, phoneNumber,
  }) {
    const res = await axios.get(`${Gen.getApiEndpoints().SMARTYSTREETS_URL}/street-address`, {
      params: {
        // "auth-id": config.smartyStreetsAuthId,
        // "auth-token": config.smartyStreetsAuthToken,
        street,
        state,
        city,
        zipcode,
        key: '16182431913915113',
      },
      headers: {
        Referer: 'http://localhost/', // TODO: change this to pro365.com after coordinating with Adam
      },
    });
    console.log('res', res);
    if (res.data.length < 1) {
      return {
        res: false,
        desc: "Invalid Address",
      };
    }

    const zipRes = await this.validateZip(street, city, state);
    const filteredZip = zipRes.filter(ele => ele.zipcode === zipcode);
    if (filteredZip.length < 1) {
      return {
        res: false,
        desc: "ZipCode is incorrect",
      };
    }
    const phoneRes = this.validatePhoneNumber(phoneNumber);
    if (!phoneRes) {
      return {
        res: false,
        desc: "Phone Number is not correct",
      };
    }
    return {
      res: true,
      desc: "All green",
    };
  }

  static validatePhoneNumber(num) {
    const a = /^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/;
    return a.test(num);
  }

  static getCommaSeparatedValues(arr) {
    let value = arr.reduce((accumulator, c) => (`${accumulator}, ${c}`), '');
    value = value ? value.slice(1) : '';
    return value;
  }

  static waitForTime(ms) {
    return new Promise((resolve, _reject) => {
      setTimeout(() => {
        resolve();
      }, ms);
    });
  }

  static getFormattedCurrency(number) {
    if (!Gen.formatter) {
      Gen.formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 0,
      });
    }
    return Gen.formatter.format(number);
  }

  static getFormattedNumber(number) {
    if (!Gen.numberFormatter) {
      Gen.numberFormatter = new Intl.NumberFormat('en-US', {
        minimumFractionDigits: 0,
      });
    }
    return Gen.numberFormatter.format(number);
  }

  static async generateThumbnail(videoUrl) {
    console.log('generateThumbnail', videoUrl);
    try {
      const data = await VideoThumbnails.getThumbnailAsync(
        videoUrl,
      );
      console.log(data);
      return data.uri;
    } catch (e) {
      console.log('in catch');
    }
  }

  static getBaseURL() {
    return Gen.getFromSyncStorage(Constants.BASE_URL) || Constants.STAGING_URL;
  }

  static spliceArray(arr, index) {
    const newArr = arr;
    newArr.splice(index, 1);
    return newArr;
  }

  static getMaximumSalary(type, rate) {
    const maxRate = type.toUpperCase() === 'HOURLY'
      ? (rate > MAX_HOURLY_RATE ? `${Gen.getFormattedCurrency(MAX_HOURLY_RATE)}+` : `${Gen.getFormattedCurrency(rate)}`)
      : (rate > MAX_SALARY_RATE ? `${Gen.getFormattedCurrency(MAX_SALARY_RATE)}+` : `${Gen.getFormattedCurrency(rate)}`);
    return maxRate;
  }

  static async sendNotification(receiverId, type, senderName) {
    let msg;
    if (type === "request") {
      msg = `${senderName} has requested to chat. ${String.fromCodePoint(0x1F440)}`;
    } else if (type === "accept") {
      msg = `${senderName} has accepted your chat request. ${String.fromCodePoint(0x1F60E)}`;
    } else if (type === "sent") {
      msg = `${senderName} has sent you a message. ${String.fromCodePoint(0x1F4AC)}`;
    } else {}
    try {
      const notificationData = {
        title: "Alert",
        body: msg,
      };
      const customData = {
        title: "Alert",
        body: msg,
        type,
      };
      const res = await axios.post(Gen.getApiEndpoints().PUSH_NOTIFY, {
        users: [receiverId],
        notificationData: {
          ...notificationData,
          data: JSON.stringify(customData),
        },
      });
      console.log('res', res);
    } catch (e) {
      console.log(e);
    }
  }

  static showPermissionAlert(permissionText) {
    Alert.alert(
      permissionText,
      '',
      [
        {
          text: 'Cancel',
          onPress: () => console.log('Cancel'),
          style: 'cancel',
        },
        {
          text: 'Settings',
          onPress: () => {
            Linking.openURL('app-settings:');
          },
        },
      ],
      { cancelable: false },
    );
  }

  static getCurrentVersion() {
    return ExpoConstants.manifest.version;
  }

  static formattedDate(date, format) {
    return moment(date).format(format);
  }

  static firstLetterCapital(str) {
    const text = str.toLowerCase();
    return text.charAt(0).toUpperCase() + text.slice(1);
  }

  static saveChatkitUser(user) {
    chatkitUser = user;
  }

  static getChatkitUser() {
    return chatkitUser;
  }

  static setActiveRoomId(roomId) {
    activeRoomId = roomId;
  }

  static getActiveRoomId() {
    return activeRoomId;
  }

  static setMessageUnread(msg) {
    unreadMessage = msg;
  }

  static getUnreadMessage() {
    return unreadMessage;
  }
}
