import * as React from 'react';
import {
  Alert, Linking, Platform, StatusBar, StyleSheet, View,
} from 'react-native';
import * as Updates from 'expo-updates';
import * as Font from 'expo-font';
import Constants from 'expo-constants';
import EStyleSheet from 'react-native-extended-stylesheet';
import axios from 'axios';
import get from 'lodash/get';
import Modal from 'expo-modal';
import Spinner from "react-native-loading-spinner-overlay";
import * as Sentry from '@sentry/react-native';
import { Gen, spinnerRef } from './src/utils/Gen';
import store from './src/store/Store';
import Colors from "./src/utils/constants/Colors";
import 'react-toastify/dist/ReactToastify.css';
const Screen = React.lazy(() => import("./src/Screen"));
const DocumentMeta = React.lazy(() => import("react-document-meta"));
const ToastContainer = React.lazy(() => import("react-toastify").then(module => ({
  default: module.ToastContainer,
})));
const Provider = React.lazy(() => import("react-redux").then(module => ({
  default: module.Provider,
})));

const meta = {
  title: 'Pro365 Employer',
  description: 'Perfect Match For Your Career. Take the next big step without digging through the trenches. Find quality matching jobs quicker',
  meta: {
    name: {
      "og:url": 'https://employer.pro365.com',
      "og:type": "website",
      "og:title": "Pro 365",
      "og:description": "Perfect Match For Your Career. Take the next big step without digging through the trenches. Find quality matching jobs quicker.",
      "og:image": "https://employer.pro365.com/images/fbjumbo.png",
    },
  },
};

Sentry.setRelease(Constants.manifest.version);
Gen._DEV_() && Sentry.init({
  dsn: 'https://9dc0f9ff232c428699b57d7ccbaa11b7@sentry.io/1804762',
  debug: false,
  environment: Constants.manifest.releaseChannel,
  release: Constants.manifest.version,
  enableInExpoDevelopment: true,
  os: Platform.OS,
  beforeSend(event, hint) {
    const error = hint.originalException;
    event.extra = {
      ...event.extra,
      ...{
        name: error.name,
        message: error.message,
        fileName: error.fileName,
        lineNumber: error.lineNumber,
        // stack: error.stack,
        sentryEventId: hint.event_id,
        environment: Constants.manifest.releaseChannel,
        os: Platform.OS,
        debug: !Gen._DEV_(),
      },
    } || {};
    return event;
  },
});

// Sentry.captureException(new Error('Oops!'));

const { width, height } = Gen.getDimension();

const appStyle = {
  appContainer: {
    backgroundColor: Colors.black,
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  container: {
    flex: 1,
    backgroundColor: Colors.white,
  },
};

const styles = Gen.isWeb() ? appStyle : StyleSheet.create(appStyle);

EStyleSheet.build({
  $rem: Gen.isWeb() ? 14 : width / 23,
});


class App extends React.Component {
    state = {
      loaded: false,
      spin: false,
      width: Gen.getDimension().width,
      height: Gen.getDimension().height,
    };

    async componentDidMount() {
      await this.checkForUpdates();
      // this.setApp();
      Gen.isWeb() && window.addEventListener('resize', () => {
        Gen.updateDimension();
        this.setState({ width: Gen.getDimension().width, height: Gen.getDimension().height });
      });
    }

    setApp = async () => {
      StatusBar.setHidden(true);
      // const url = await Linking.getInitialURL();
      await Promise.all([Font.loadAsync({
        'Nunito-Regular': require('./assets/fonts/Nunito/Nunito-Regular.ttf'),
        'Nunito-SemiBold': require('./assets/fonts/Nunito/Nunito-SemiBold.ttf'),
        'Nunito-Bold': require('./assets/fonts/Nunito/Nunito-Bold.ttf'),
        Roboto: require("native-base/Fonts/Roboto.ttf"),
        Roboto_medium: require('native-base/Fonts/Roboto_medium.ttf'),
      }),
      await Gen.initApp(),
      ]);
      // await Gen.registerForPushNotificationsAsync();
      this.setState({
        loaded: true,
      });
    };

    checkForUpdates = async () => {
      try {
        const res = await axios.get(Gen.isModuleApplicant() ? Gen.getApiEndpoints().VERSION_INFO_APPLICANT : Gen.getApiEndpoints().VERSION_INFO_EMPLOYER);
        if (res.status === 200) {
          // console.log('updates', res);
          const { minimumVersion, latestVersion, minimumPlayStoreVersion } = res.data;
          if (minimumVersion > Gen.getCurrentVersion()) {
            if (minimumPlayStoreVersion > Gen.getCurrentVersion()) {
              Alert.alert(
                'This version of the app is now out-dated',
                '',
                [
                  {
                    text: 'Update',
                    onPress: () => Linking.openURL('https://play.google.com/store/apps/details?id=com.proexpo'),
                  },
                ],
                { cancelable: false },
              );
            } else {
              try {
                this.setState({ spin: true });
                await Updates.fetchUpdateAsync();
                this.setState({ spin: false }, () => {
                  Alert.alert(
                    'New Version is Updated. Refresh the app to use it.',
                    '',
                    [
                      {
                        text: 'Refresh',
                        onPress: () => Updates.reloadFromCache(),
                      },
                    ],
                    { cancelable: false },
                  );
                });
                // ... notify user of update ...
                // );
              } catch (e) {
                // console.log('e', e);
                this.setState({ spin: false });
                // alert(JSON.stringify(e, null, 3));
              }
            }
          } else if (Gen.getCurrentVersion() < latestVersion && Gen.getCurrentVersion() >= minimumVersion) {
            try {
              await this.setApp();
              await Updates.fetchUpdateAsync();
              // ... notify user of update ...
              Alert.alert(
                'New Version is Updated. Refresh the app to use it.',
                '',
                [
                  {
                    text: 'Cancel',
                    onPress: () => console.log('Cancel'),
                    style: 'cancel',
                  }, {
                    text: 'Refresh',
                    onPress: () => Updates.reloadFromCache(),
                  },
                ],
                { cancelable: false },
              );
            } catch (e) {
              // console.log('e', e);
              // alert(JSON.stringify(e, null, 3));
            }
          } else {
            await this.setApp();
          }
        }
      } catch (e) {
        // console.log(e);
        alert(get(e, 'response.data.error') || Constants.GENERIC_ERROR);
      }
    };


    render() {
      return this.state.loaded ? (
        <React.Suspense fallback={<View />}>
          <DocumentMeta {...meta}>
            <View style={styles.appContainer}>
              <View style={{
                height: this.state.height,
                width: this.state.width,
                maxWidth: '1500px',
                minWidth: '760px',
                overflowX: 'scroll',
                overflowY: 'scroll',
                justifyContent: 'flex-start',
              }}
              >
                <React.Suspense fallback={<View />}>
                  <Provider store={store}>
                    {
                                    Modal.wrapIntoModal((
                                      <View style={styles.container}>
                                        <React.Suspense fallback={<View />}>
                                          <Screen userData={this.state.userData} />
                                        </React.Suspense>
                                      </View>), {
                                      height: this.state.height,
                                      width: this.state.width,
                                      minWidth: '760px',
                                      backgroundColor: 'rgba(1,1,56,0.3)',
                                    })
                                }
                  </Provider>
                </React.Suspense>
              </View>
              {
                        Gen.isWeb() && (
                        <React.Suspense fallback={<View />}>
                          <ToastContainer />
                        </React.Suspense>
                        )
                    }
            </View>
          </DocumentMeta>
        </React.Suspense>
      ) : (
        <Spinner
          visible={this.state.spin}
        />
      );
    }
}


export default App;
