export default {
  SKILLS: "SKILLS",
  USER_DATA: "USER_DATA",
  IMAGE_URL: "IMAGE_URL",
  JOB_DETAILS: "JOB_DETAILS",
  DOC_URL: "DOC_URL",
  TOKEN: "TOKEN",
  firstName: 'firstName',
  lastName: 'lastName',
  userId: 'userId',
  UNHIDEBOTTOMTAB: 'UNHIDEBOTTOMTAB',
  RECENT_SEARCHES: 'RECENT_SEARCHES',
  FILTER_DATA: 'FILTER',
  EMPLOYER_FILTER_DATA: 'EMPLOYER_FILTER_DATA',
  BASE_URL: 'BASE_URL',
  STAGING_URL: 'https://staging-api.pro365.com',
  PRODUCTION_URL: 'https://api.pro365.com',
  GENERIC_ERROR: 'Something went wrong',
  CLIENT_ID: '81zbkgn4dfpb8c',
  REDIRECT_URL: 'https://www.pro365.com/auth/callback',
  USER_LOGGED_IN: true,
  USER_LOGGED_OUT: false,
};
